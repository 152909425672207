import React from 'react';
import { graphql } from 'gatsby';
import RichText from "../components/richText";
import SEO from '../components/seo';
import { default as PageEl } from '../components/common/layouts/page';
import SliceZone from '../components/sliceZone';

export const query = graphql`
	query PageQuery($id: String) {
		prismic {
			allPages(id: $id) {
				edges {
					node {
						page_title
						page_content
						_meta {
							uid
							id
						}
						body {
							...pageSlices
						}
					}
				}
			}
		}
	}
`;

const Page = ({data, location}) => {
	const pageTitle = data?.prismic?.allPages?.edges[0]?.node?.page_title[0].text;
	const content = data?.prismic?.allPages?.edges[0]?.node?.page_content;
	if(!data){
		return null;
	}
	return (
		<>
			<SEO title={pageTitle}/>
			<PageEl>
				<RichText render={pageTitle} />
				{!!content && <RichText render={content} />}
				{!!data?.prismic?.allPages?.edges[0]?.node.body && (
					<SliceZone body={data?.prismic?.allPages?.edges[0]?.node.body} location={location}/>
				)}
			</PageEl>
		</>
	);
};

export default Page;
