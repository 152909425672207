import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
	width: 100%;
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	padding: 20px 30px;
	font-size: 25px;
	line-height: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	img {
		display: block;
		width: 100%;
		height: auto;
		max-width: 400px;
		max-height: 400px;
		object-fit: contain;
		margin: 0 auto;

		@media only screen and (max-width: 600px) {
			max-width: 300px;
		}
	}

	a {
		color: var(--primary-color);
	}

	hr {
		border: 1px solid var(--secondary-color);
		background-color: var(--secondary-color);
	}

	blockquote {
		border-left: 4px solid var(--secondary-color);
		padding-left: 10px;
		padding-right: 10px;
	}
`;

const Page = ({ children }) => {
	return <PageWrapper>{children}</PageWrapper>;
};

export default Page;
